import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Link from 'gatsby-link';

const PassportStudioWrapper = styled.div`
  text-align: center;
  margin: 100px auto;
  width: 200px;
  @media (min-width: 768px) {
    margin: 140px auto;
    width: 300px;
  }
  @media (min-width: 1024px) {
    margin: 180px auto;
    width: 400px;
  }
`;

function PassportStudio() {
  return (
    <PassportStudioWrapper>
      <Link to='apps/passport-studio/'>
        <StaticImage
          layout='fullWidth'
          loading='eager'
          sizes='(max-width: 400px) 200px, (max-width: 800px) 300px, (max-width: 1200px) 400px'
          src='./apps/passport-studio/screenshot_new_photo.png'
          alt='Screenshot of Passport Studio app on Android.'
        />
      </Link>
      <p className='content mt-6'>
        Introducing <Link to='apps/passport-studio/'>Passport Studio</Link>:
        Beautiful passport photos at home.
      </p>
    </PassportStudioWrapper>
  );
}

const HeroImageWrapper = styled.div`
  margin: 0 auto 60px;
  @media (min-width: 768px) {
    margin: -100px auto 60px;
  }
`;

function HeroImage() {
  return (
    <HeroImageWrapper>
      <StaticImage
        layout='fullWidth'
        loading='eager'
        sizes='(max-width: 400px) 400px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 100vw'
        src='./splash.png'
        alt='Modern Work.'
      />
    </HeroImageWrapper>
  );
}

const HeroBodyWrap = styled.div`
  text-align: center;
  margin: 100px auto 0 auto;
  max-width: 350px;
  h2,
  p {
    margin: auto;
    max-width: 90%;
  }
  
  @media (min-width: 768px) {
    max-width: 500px;
  }
  @media (min-width: 1024px) {
    h2,
    p {
      margin: auto;
      max-width: 470px;
    }

    margin: 230px auto 0 auto;
    max-width: 700px;
  }
`;

const IndexPage = () => {
  return (
    <>
      <SEO
        title='High Point Engineering'
        description='A software engineering firm based in Washington. Ten years of experience delivering highly-available software.'
      />
      <div id='flex-root'>
        <main>
          <div className='container'>
            <HeroImage />
          </div>
          <HeroBodyWrap className=''>
            <div className='container has-text-centered'>
              <h1 className='title is-size-3 is-size-1-tablet'>
                High Point Engineering
              </h1>
              <p className='content subtitle'>
                We're a software engineering firm based in Washington, with ten
                years of experience delivering highly-available software.
                Secure, fast, reliable: Pick three.
              </p>
            </div>
          </HeroBodyWrap>
          <PassportStudio />
        </main>
      </div>
    </>
  );
};

export default IndexPage;
